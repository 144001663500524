import { Controller } from "stimulus"
import $ from "jquery"

export default class extends Controller {
  static targets = [ "details", "checkbox" ]

  connected() {
    this.presenceChanged()
  }

  presenceChanged() {
    if ($(this.checkboxTarget).is(':checked')) {
      $(this.detailsTarget).removeClass('d-none')
    } else {
      $(this.detailsTarget).addClass('d-none')
    }
  }
}
