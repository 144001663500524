import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'
import $ from "jquery"

export default class extends Controller {
  static targets = [ "destroyFlag" ]

  connect() {
    StimulusReflex.register(this)
  }

  markDestroy(e) {
    e.preventDefault()
    this.destroyFlagTarget.value = "1"
    $(this.element).hide(250)
  }

  approveFile(event) {
    event.preventDefault()
    this.stimulate('Request::File#change_status', this.data.get('id'), this.data.get('fieldPrefix'), 'approve')
  }

  rejectFile(event) {
    event.preventDefault()
    this.stimulate('Request::File#change_status', this.data.get('id'), this.data.get('fieldPrefix'), 'reject')
  }
}
