import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  addComment(e) {
    e.preventDefault()
    this.stimulate('Request::Comment#add', { serializeForm: false }, this.data.get('selector'), this.data.get('fieldPrefix'))
  }
}
