import { Controller } from "stimulus"
import $ from 'jquery'
import { fire } from "@rails/ujs"
import $$ from "mz_ui/utils/dom"

export default class extends Controller {
  doHighlightTabErrors(event) {
    const data = event.detail[0];

    const load_url = data.url_tab_with_errors;
    if (!data.success && load_url) {
      const tab_url = load_url.split('?')[0];
      const tab_link = $$("a[href*='" + tab_url + "']");
      tab_link.setAttribute('href', load_url);
      fire(tab_link, 'click');
    }
  }
}
