import { Controller } from "stimulus"
import $ from 'jquery'

export default class extends Controller {
  connect() {
    this.toggleOrg(
      this.data.has('roles') && (
        this.data.get('roles') === 'applicant' ||
        this.data.get('roles') === 'expert' ||
        this.data.get('roles') === 'tsekkmp' ||
        this.data.get('roles') === 'head_specialist' ||
        this.data.get('roles') === 'observer'
      ),
      this.data.has('roles') && this.data.get('roles') === 'observer'
    )
  }

  doRoleSelect(event) {
    const roleId = event.target.value
    this.toggleOrg(
      roleId === '2' ||
      roleId === '4' ||
      roleId === '5' ||
      roleId === '6' ||
      roleId === '7',
      roleId === '7'
    )
  }

  toggleOrg(isOrg, isObs) {
    if (isOrg) {
      $('#organization').show()
    } else {
      $('#organization').hide()
    }
    if (isObs) {
      $('#request_add_permission').show()
    } else {
      $('#request_add_permission').hide()
    }
  }
}
