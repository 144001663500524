import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  addCoursePrice(e) {
    e.preventDefault()
    this.stimulate('Offer::CoursePrice#add', { serializeForm: false }, this.data.get('selector'), this.data.get('fieldPrefix'), this.data.get('showAllFields'))
  }
}
