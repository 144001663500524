import { Controller } from "stimulus"
import StimulusReflex from 'stimulus_reflex'

export default class extends Controller {
  connect() {
    StimulusReflex.register(this)
  }

  loadDrug(event) {
    let data = event.detail.event.params.data
    if (!data.id) { return }

    this.stimulate('Offer::Drug#details', { serializeForm: false }, data.id, this.data.get('offerKind'))
  }
}
